import React from "react";
import DashboardLayout from "./layouts/Dashboard";
import LandingLayout from "./layouts/Landing";
// categories
import CreateCategory from "./pages/pages/categories/CreateCategory";
import Categories from "./pages/pages/categories/Categories";
import EditCategory from "./pages/pages/categories/EditCategory";
import CategoryDetail from "./pages/pages/categories/CategoryDetail";
// products
import ProductFieldsEditor from "./pages/pages/products/productFieldsEditor";
import ProductsList from "./pages/pages/products/productsList";
import CreateProduct from "./pages/pages/products/CreateProduct";
import EditProduct from "./pages/pages/products/editProduct";
// fonts
import SaveFonts from "./pages/pages/fonts/saveFonts";
import FontsList from "./pages/pages/fonts/fontsList";
// tags
import Tags from "./pages/pages/tags";
import TagCategories from "./pages/pages/tags/TagCategories";
import AutofillableFields from "./pages/pages/autofillableFields";
// shul and hall name/address
import NameAndAddressForm from "./pages/pages/shul-hall-address/addNameAndAddress";
import ShullOrHallList from "./pages/pages/shul-hall-address/shulOrHallList";
import EditShulOrHall from "./pages/pages/shul-hall-address/editShulOrHall";
// people names
import SavePeopleNames from "./pages/pages/peopleNames/savePeopleNames";
import PeopleNamesList from "./pages/pages/peopleNames/peopleNamesList";
// watermarks
import Watermarks from "./pages/pages/watermarks/watermarksIndex";
// edit customer cards
import EditCustomerCardSearch from "./pages/pages/products/purchasesEditor/editCustomerCardSearch";
import EditCustomerCardEditor from "./pages/pages/products/purchasesEditor/editCustomerCardEditor";
// fix order
import FixOrder from "./pages/pages/fixOrder";
// order list 
import { OrderList } from "./pages/pages/order/Orderlist";
// temp file
import TempFile from "./temp";

import SignIn from "./pages/auth/SignIn";

const routes = [
  {
    path: "/",
    element: <LandingLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "pages",
    element: <DashboardLayout />,
    children: [
      {
        path: "createCategory",
        element: <CreateCategory />,
      },
      {
        path: "categories",
        element: <Categories />,
      },
      {
        path: "category/:categoryId",
        element: <CategoryDetail />,
      },
      {
        path: "editCategory/:categoryId",
        element: <EditCategory />,
      },
      {
        path: "products",
        element: <ProductsList />,
      },
      {
        path: "createProduct",
        element: <CreateProduct />,
      },
      {
        path: "editProduct/:productId",
        element: <EditProduct />,
      },
      {
        path: "editProductFields/:productId",
        element: <ProductFieldsEditor />,
      },
      {
        path: "saveFonts",
        element: <SaveFonts />,
      },
      {
        path: "fontsList",
        element: <FontsList />,
      },
      {
        path: "tags",
        element: <Tags />,
      },
      {
        path: "tagCategories",
        element: <TagCategories />,
      },
      {
        path: "autofillableFields",
        element: <AutofillableFields />,
      },
      {
        path: "saveHall",
        element: <NameAndAddressForm typeOfForm="hall" />,
      },
      {
        path: "saveShul",
        element: <NameAndAddressForm typeOfForm="shul" />,
      },
      {
        path: "halls",
        element: <ShullOrHallList typeOfList="hall" />,
      },
      {
        path: "shuls",
        element: <ShullOrHallList typeOfList="shul" />,
      },
      {
        path: "edit-hall",
        element: <EditShulOrHall />,
      },
      {
        path: "edit-shul",
        element: <EditShulOrHall />,
      },
      {
        path: "savePeopleNames",
        element: <SavePeopleNames />,
      },
      {
        path: "peopleNames",
        element: <PeopleNamesList />,
      },
      {
        path: "watermarks",
        element: <Watermarks />,
      },
      {
        path: "edit-customer-card",
        element: <EditCustomerCardSearch />,
      },
      {
        path: "edit-customer-card-editor",
        element: <EditCustomerCardEditor />,
      },
      {
        path: "fix-order",
        element: <FixOrder />,
      },
      {
        path: "OrderList",
        element: <OrderList />
      },

      // temp
      {
        path: "temp",
        element: <TempFile />,
      },
    ],
  },
];

export default routes;
