import ajax from './ajax';
import { API_BASE_URL, WP_API_BASE_URL } from '../config';
// utils
import { JSON2FormData } from '../utils/formData';

const customerPurchasesRequests = {};

// --- MongoDB ---

customerPurchasesRequests.getCustomerPurchasesByEmail = async function ({ customerEmail }) {
    console.log(customerEmail, "customerEmail");

    try {
        const url = `${API_BASE_URL}/purchases/GetCustomerPurchases?customerEmail=${customerEmail}`;
        const purchases = await ajax.get(url, { token: true });
        console.log(purchases, "purchases");

        return purchases;
    } catch (err) {
        console.log('Error getting customer orders by email');
        return null;
    }
}


customerPurchasesRequests.initEditCount = async function ({
    wooCartItemKey,
    orderId,
    // wooOrderItemId,
    customerEmail
}) {
    const url = `${API_BASE_URL}/purchases/InitEditCount`;

    try {
        const countStarted = await ajax.post(url, {
            wooCartItemKey,
            orderId,
            // wooOrderItemId,
            customerEmail
        });
        return countStarted;

    } catch (error) {
        console.log('Error initializing edit count', error);
        return null;
    }
}



customerPurchasesRequests.getWooOrderItem = async function ({ wooCartItemKey, orderId, wooOrderItemId }) {
    try {
        const url = `${API_BASE_URL}/purchases/get_order_item`;
        const result = await ajax.post(url, {
            orderId,
            wooCartItemKey,
            wooOrderItemId
        });
        return result;

    } catch (err) {
        console.log('Error order item', err);
        return null;
    }
}


customerPurchasesRequests.updateWooOrderItem = async function ({
    orderId,
    wooOrderItemId,
    wooCartItemKey,
    customization_data,
    customized_item_preview
}) {
    try {

        const url = `${API_BASE_URL}/purchases/update_order_item`;
        const result = await ajax.post(url, {
            orderId,
            wooOrderItemId,
            wooCartItemKey,
            customization_data,
            customized_item_preview
        });
        return result;

    } catch (err) {
        console.log('Error updating order item in wordpress', err);
        return false;
    }
}

export default customerPurchasesRequests;